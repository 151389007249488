.hero {
  display: flex;
}

.heroLeft {
  flex: 1;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.home {
  display: flex;
  gap: 10px;
  align-items: center;
}
.logout {
  display: flex;
  gap: 10px;
  align-items: center;
}
.heroCenter {
  flex: 2;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  height: 100vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.centerTop {
  display: flex;
  flex-direction: column;
  gap: 50px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 60px;
}
.Warn {
  padding: 10px;
  border-radius: 10px;
  background-color: antiquewhite;
  color: black;
}
.centerBottom {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
}

.pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.pic > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.username {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.username > input {
  padding: 10px;
  outline: none;
  border: 1px solid gray;
}
.update {
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #764abc;
  cursor: pointer;
  color: white;
}

.heroRight {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.heroRight > img {
  width: 90%;
  height: 200px;
  object-fit: cover;
}
